import Link from 'next/link';

import { ComponentPropsWithoutRef, ComponentType, ElementType, MouseEvent, ReactNode } from 'react';

import classNames from 'classnames';

import { useDrawer } from '@uikit/components/Drawer';

export interface DrawerMenuItemGeneralProps {
  Icon?: ComponentType;
  label?: ReactNode;
  children?: ReactNode;
  className?: string;
  isActive?: boolean;
  isSub?: boolean;
  closeDrawerOnClick?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

export type DrawerMenuItemProps<C extends ElementType> = DrawerMenuItemGeneralProps & {
  Component?: C;
  iconClassName?: string;
} & ComponentPropsWithoutRef<C>;

const DrawerMenuItem = <C extends ElementType = typeof Link>({
  Component,
  label,
  className,
  children,
  Icon,
  onClick,
  closeDrawerOnClick = true,
  isSub,
  iconClassName,
  ...props
}: DrawerMenuItemProps<C>) => {
  const { close } = useDrawer();
  const As = Component || Link;
  const isLink = As === Link;

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(e);
    }

    if (closeDrawerOnClick) {
      close();
    }
  };

  return (
    <As
      className={classNames(
        'flex min-h-[56px] items-center py-2',
        isSub ? 't-body2 pl-10 sm:pl-16 pr-2' : 'pl-8 pr-2 t-h4',
        className,
      )}
      onClick={handleClick}
      // TODO: fix type
      {...(props as any)}
      {...(isLink ? { prefetch: false } : {})}
    >
      {Icon && (
        <span
          className={classNames('icon-[28px] mr-4 text-neutral-300 opacity-100', iconClassName)}
        >
          <Icon />
        </span>
      )}
      {label}
      {children}
    </As>
  );
};

export default DrawerMenuItem;
