'use client';

import dynamic from 'next/dynamic';

import { useModalController } from '@uikit';

import { Drawer, DrawerHeader } from '@uikit/components/Drawer';

import { MIXPANEL_EVENT_NAMES, MixpanelEventName } from '@shared/api/mixPanel';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import ReferAFriendBanner from '@store:web/components/pages/ReferAFriend/ReferAFriendBanner';

import { sidebarNavItems } from '../Sidebar/Sidebar';
import DrawerMenu from '../TopNavbar/DrawerMenu';
import DrawerMenuItem from '../TopNavbar/DrawerMenuItem';
import DrawerMenuLink from '../TopNavbar/DrawerMenuLink';

const KarmaModal = dynamic(() => import('../KarmaModal/KarmaModal'));
const NotificationModal = dynamic(() => import('../NotificationModal/NotificationModal'));
const SupportModal = dynamic(
  () => import('@store:web/components/common/SupportModal/SupportModal'),
);

interface StoreNavMenuProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const StoreNavMenu = ({ isOpen, onClose }: StoreNavMenuProps) => {
  const { user, isAuth, isLoading } = useAuth();
  const modalKarma = useModalController();
  const modalNotification = useModalController();
  const supportModal = useModalController();
  const authModal = useAuthModal();
  const featureToggles = useFeatureToggles();

  const { track } = useMixPanel();

  const handleTrackedLinkClick = (eventName: MixpanelEventName) => {
    if (user?.id) {
      track(eventName, {
        distinct_id: user.id,
      });
    }
  };

  const handleProtectedNavItemClick = (event: any) => {
    if (isLoading) {
      event.preventDefault();
    }

    if (!isAuth) {
      event.preventDefault();
      authModal.open();
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} className="bg-neutral-600 text-neutral-50">
        <DrawerHeader title="Menu" />

        <DrawerMenu className="divide-y divide-neutral-50/20 border-y border-neutral-50/20">
          <DrawerMenuLink {...sidebarNavItems.magicId} onClick={handleProtectedNavItemClick} />

          <DrawerMenuLink {...sidebarNavItems.store} />

          <DrawerMenuLink
            iconClassName="opacity-60"
            {...sidebarNavItems.boost}
            data-onboarding-store-nav-menu-item="magic-boost"
            onClick={handleProtectedNavItemClick}
          />

          {featureToggles.perks && (
            <DrawerMenuLink iconClassName="opacity-60" {...sidebarNavItems.perks} />
          )}

          {featureToggles.staking && (
            <DrawerMenuLink
              {...sidebarNavItems.staking}
              target="_blank"
              rel="noreferrer"
              onClick={() => handleTrackedLinkClick(MIXPANEL_EVENT_NAMES.STAKING_LINK_CLICK)}
            />
          )}

          <DrawerMenuLink
            {...sidebarNavItems.otc}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleTrackedLinkClick(MIXPANEL_EVENT_NAMES.OTC_LINK_CLICK)}
          />

          {featureToggles.launchpad && (
            <DrawerMenuLink
              {...sidebarNavItems.launchpad}
              target="_blank"
              rel="noreferrer"
              onClick={() => handleTrackedLinkClick(MIXPANEL_EVENT_NAMES.LAUNCHPAD_LINK_CLICK)}
            />
          )}

          {featureToggles.rewardPools && <DrawerMenuLink {...sidebarNavItems.rewardPools} />}

          {isAuth && (
            <DrawerMenuItem
              iconClassName="opacity-60"
              Component="button"
              {...sidebarNavItems.notifications}
              onClick={modalNotification.open}
            />
          )}

          {isAuth && (
            <DrawerMenuItem
              iconClassName="opacity-60"
              Component="div"
              {...sidebarNavItems.karma}
              onClick={modalKarma.open}
            />
          )}

          <DrawerMenuLink
            data-onboarding-store-nav-menu-item="docs"
            iconClassName="opacity-60"
            {...sidebarNavItems.docs}
          />

          <DrawerMenuItem
            iconClassName="opacity-60 h-[26px]"
            Component="button"
            data-onboarding-store-nav-menu-item="support"
            {...sidebarNavItems.support}
            onClick={supportModal.open}
          />
        </DrawerMenu>

        <ReferAFriendBanner onClick={onClose} className="p-7" />
      </Drawer>
      {isAuth && (
        <>
          {modalNotification.isOpen && <NotificationModal {...modalNotification.props} />}
          {modalKarma.isOpen && <KarmaModal {...modalKarma.props} />}
        </>
      )}

      {supportModal.isOpen && <SupportModal {...supportModal.props} />}
    </>
  );
};

export default StoreNavMenu;
