import { useTranslations } from 'next-intl';

import { useClientFeatureTogglesQuery } from '@query';

export const useGetBanner = (): { banner?: string; className?: string } => {
  const { data: featureToggles, isPending: isLoading } = useClientFeatureTogglesQuery();
  const t = useTranslations('layout');

  switch (true) {
    case !isLoading && featureToggles?.greenNotificationBanner:
      return {
        banner: t.raw('notificationBanner_green'),
        className: 'notification-banner-green',
      };
    case !isLoading && featureToggles?.redNotificationBanner:
      return {
        banner: t.raw('notificationBanner_red'),
        className: 'notification-banner-red',
      };
    case !isLoading && featureToggles?.gradientNotificationBanner:
      return {
        banner: t.raw('notificationBanner_gradient'),
        className: 'notification-banner-gradient',
      };
    default:
      return {};
  }
};
