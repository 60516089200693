import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

type DrawerMenuProps = ComponentPropsWithoutRef<'nav'>;

const DrawerMenu = ({ className, ...props }: DrawerMenuProps) => {
  return <nav className={classNames('flex flex-col', className)} {...props} />;
};

export default DrawerMenu;
