import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';

import { useDisconnect } from 'wagmi';

import { Dropdown, DropdownItem, useModalController } from '@uikit';

import { Avatar as AvatarIcon } from '@uikit/icons/Avatar';
import Disconnect from '@uikit/icons/Disconnect';
import Settings from '@uikit/icons/Settings';
import Support from '@uikit/icons/Support';

import { useAuth } from '@shared/common/providers/AuthProvider';
import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';

import Avatar from '@store:web/components/common/Avatar/Avatar';

import SidebarNavButton from './SidebarNavButton';

const SupportModal = dynamic(
  () => import('@store:web/components/common/SupportModal/SupportModal'),
);

const SidebarNavAccountButton = () => {
  const router = useRouter();
  const { isAuth, user, logout } = useAuth();
  const loginModal = useAuthModal();
  const { disconnect } = useDisconnect();

  const supportModal = useModalController();

  const handleDisconnect = () => {
    logout();
    disconnect();
  };

  if (!isAuth) {
    return (
      <SidebarNavButton label="Account" compact onClick={loginModal.open}>
        <div className="w-full h-full flex overflow-hidden items-center justify-center rounded-full bg-neutral-600 group-hover:shadow-nav-item transition-all border border-neutral-50/25">
          <AvatarIcon className="icon-lg" />
        </div>
      </SidebarNavButton>
    );
  }

  return (
    <>
      <Dropdown
        showOnHover
        placement="right-end"
        trigger={
          <SidebarNavButton className="px-1" label="Account" compact>
            <Avatar
              userInfo={{
                magicId: user.attributes.name,
                displayName: user.attributes.displayedName,
                membership: {
                  tier: user?.membershipTier?.attributes?.key,
                },
              }}
              isLink={false}
              src={user.attributes.icon}
              sizeInPX={38}
              containerClassName="w-full h-full flex overflow-hidden items-center justify-center rounded-full bg-neutral-600 group-hover:shadow-nav-item transition-all border border-neutral-50/25"
              className="w-full h-full"
              data-qa="account-avatar"
            />
          </SidebarNavButton>
        }
      >
        <DropdownItem
          label="Settings"
          className="flex gap-2.5 items-center"
          onClick={() => router.push('/profile/settings')}
        >
          <Settings className="icon-sm text-primary-200" />
          Settings
        </DropdownItem>
        <DropdownItem
          label="Settings"
          className="flex gap-2.5 items-center"
          onClick={() => supportModal.open()}
        >
          <Support className="icon-sm text-primary-200" />
          Support
        </DropdownItem>
        <DropdownItem
          label="Logout"
          className="flex gap-2.5 items-center text-secondary"
          onClick={handleDisconnect}
        >
          <Disconnect className="icon-sm text-secondary-200 p-0.5" />
          Disconnect
        </DropdownItem>
      </Dropdown>

      {supportModal.isOpen && <SupportModal {...supportModal.props} />}
    </>
  );
};

export default SidebarNavAccountButton;
