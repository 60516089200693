import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { useIsPathnameLinkHref } from '@store:web/utils/hooks/usePathnameMatchUrl';

import DrawerMenuItem, { DrawerMenuItemProps } from './DrawerMenuItem';

type DrawerMenuLinkProps = Omit<DrawerMenuItemProps<typeof Link>, 'isActive'> & {
  isActive?: ((params: { pathname: string }) => boolean) | boolean;
};

const DrawerMenuLink = ({ isActive, href, ...props }: DrawerMenuLinkProps) => {
  const pathname = usePathname();
  const isMatch = useIsPathnameLinkHref(href);
  const active = typeof isActive === 'function' ? isActive({ pathname }) : isActive || isMatch;

  return <DrawerMenuItem href={href} isActive={active} {...props} />;
};

export default DrawerMenuLink;
