import { Url } from 'next/dist/shared/lib/router/router';
import { usePathname } from 'next/navigation';

import { ReactNode, useLayoutEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { Collapse } from '@uikit';

import AngleDown from '@uikit/icons/AngleDown';

import DrawerMenuItem from './DrawerMenuItem';
import DrawerMenuLink from './DrawerMenuLink';

interface DrawerSubMenuProps {
  label: ReactNode;
  items: {
    label: ReactNode;
    href?: string;
    target?: string;
  }[];
}

const DrawerSubMenu = ({ label, items }: DrawerSubMenuProps) => {
  const pathname = usePathname();
  const hasActiveItem = useMemo(
    () => items.some((item) => item.href === pathname),
    [pathname, items],
  );
  const [isOpen, setIsOpen] = useState(hasActiveItem);
  const toggle = () => setIsOpen((oldIsOpen) => !oldIsOpen);

  useLayoutEffect(() => {
    if (!hasActiveItem) {
      setIsOpen(false);
    }
  }, [hasActiveItem]);

  return (
    <div className="flex flex-col">
      <DrawerMenuItem
        Component="button"
        isActive={hasActiveItem || isOpen}
        onClick={toggle}
        className="max-sm:pl-4"
        closeDrawerOnClick={false}
      >
        <span className="flex items-center flex-grow">{label}</span>
        <AngleDown className={classNames('icon transition-transform', isOpen && 'rotate-180')} />
      </DrawerMenuItem>
      <Collapse isOpen={isOpen}>
        <div>
          <nav className="flex flex-col border-t border-neutral-100">
            {items.map((item) => {
              return (
                <DrawerMenuLink key={item.href} href={item.href as Url} target={item.target} isSub>
                  {item.label}
                </DrawerMenuLink>
              );
            })}
          </nav>
        </div>
      </Collapse>
    </div>
  );
};

export default DrawerSubMenu;
