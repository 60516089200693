import Link from 'next/link';
import { usePathname } from 'next/navigation';

import ProfileLayoutSidebarNavItem, {
  ProfileLayoutSidebarNavItemProps,
} from './ProfileLayoutSidebarNavItem';
type ProfileLayoutSidebarNavLinkProps = ProfileLayoutSidebarNavItemProps<typeof Link>;

const ProfileLayoutSidebarNavLink = ({
  isActive,
  href,
  ...props
}: ProfileLayoutSidebarNavLinkProps) => {
  const pathname = usePathname();
  const active =
    isActive || (typeof href === 'string' ? href === pathname : href.pathname === pathname);

  return <ProfileLayoutSidebarNavItem href={href} isActive={active} {...props} />;
};

export default ProfileLayoutSidebarNavLink;
