'use client';

import dynamic from 'next/dynamic';

import { ComponentPropsWithoutRef, MouseEvent, useEffect } from 'react';

import classNames from 'classnames';

import { useModalController } from '@uikit';

import Bell from '@uikit/icons/Bell';
import Boost from '@uikit/icons/Boost';
import GitBook from '@uikit/icons/GitBook';
import { GoldenStar } from '@uikit/icons/GoldenStar';
import KarmaPoint from '@uikit/icons/KarmaPoint';
import { Launchpad } from '@uikit/icons/Launchpad';
import { MagicSquareLogo } from '@uikit/icons/MagicSquareLogo';
import { MultiColorGift } from '@uikit/icons/MultiColorGift';
import { Otc } from '@uikit/icons/Otc';
import { Pile } from '@uikit/icons/Pile';
import Shop from '@uikit/icons/Shop';
import Spaces from '@uikit/icons/Spaces';
import Support from '@uikit/icons/Support';
import { Swap } from '@uikit/icons/Swap';

import { MIXPANEL_EVENT_NAMES, MixpanelEventName } from '@shared/api/mixPanel';
import NextLink from '@shared/common/components/NextLink';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { useAuthModal } from '@shared/common/providers/GlobalModalsProvider';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';
import { GITBOOK_URL } from '@shared/constants';
import { GENERAL_URLS } from '@shared/constants/url';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { ReactComponent as LogoSvg } from './icons/logo.svg';
import SidebarNavAccountButton from './SidebarNavAccountButton';
import SidebarNavButton from './SidebarNavButton';
import SidebarNavLink from './SidebarNavLink';

const KarmaModal = dynamic(() => import('../KarmaModal/KarmaModal'));
const NotificationModal = dynamic(() => import('../NotificationModal/NotificationModal'));

type SidebarProps = ComponentPropsWithoutRef<'aside'>;

export const sidebarNavItems = {
  magicId: {
    label: 'Magic ID',
    href: '/profile/settings',
    Icon: MagicSquareLogo,
  },
  store: {
    label: 'Store',
    href: '/',
    Icon: Shop,
  },
  spaces: {
    label: 'Spaces',
    href: '/spaces',
    Icon: Spaces,
    iconClassName: 'w-7 h-7',
  },
  boost: {
    label: 'Boost',
    href: '/magic-boost',
    Icon: Boost,
    id: 'sidebar-navitem-magic-boost',
  },
  swap: {
    label: 'Swap',
    href: '/swap',
    Icon: Swap,
  },
  launchpad: {
    label: 'LaunchPad',
    Icon: Launchpad,
    href: process.env.NEXT_PUBLIC_LAUNCHPAD_HOST || '#',
    target: '_blank',
    rel: 'noreferrer ',
  },
  notifications: {
    label: 'Notifications',
    Icon: Bell,
  },
  karma: {
    label: 'Karma',
    Icon: KarmaPoint,
  },
  docs: {
    label: 'Docs',
    href: GITBOOK_URL,
    Icon: GitBook,
    id: 'sidebar-navitem-gitbook',
    target: '_blank',
    rel: 'noreferrer ',
  },
  support: {
    label: 'Support',
    Icon: Support,
    id: 'sidebar-navitem-support',
  },
  perks: {
    label: 'Perks',
    href: '/perks',
    Icon: MultiColorGift,
  },
  staking: {
    label: 'Staking',
    href: GENERAL_URLS.staking,
    Icon: Pile,
    target: '_blank',
    rel: 'noreferrer ',
  },
  otc: {
    label: 'OTC',
    href: 'https://otc.magicsquare.io/',
    Icon: Otc,
    target: '_blank',
    rel: 'noreferrer ',
  },
  rewardPools: {
    label: 'Reward Pools',
    href: '/reward-pools',
    Icon: GoldenStar,
  },
} as const;

const Sidebar = ({ className, ...props }: SidebarProps) => {
  const { user, isAuth, isLoading } = useAuth();
  const modalKarma = useModalController();
  const notificationModal = useModalController();
  const featureToggles = useFeatureToggles();
  const authModal = useAuthModal();
  const { track } = useMixPanel();

  const handleTrackedLinkClick = (eventName: MixpanelEventName) => {
    if (user?.id) {
      track(eventName, {
        distinct_id: user.id,
      });
    }
  };

  const handleProtectedNavItemClick = (event: MouseEvent<HTMLElement>) => {
    if (isLoading) {
      event.preventDefault();
    }

    if (!isAuth) {
      event.preventDefault();

      authModal.open();
    }
  };

  useEffect(() => {
    if (!isAuth) {
      modalKarma.close();
      notificationModal.close();
    }
  }, [isAuth, modalKarma, notificationModal]);

  return (
    <aside
      className={classNames(
        'fixed left-0 h-full w-sidebar items-center flex flex-col z-sidebar pt-2 pb-2 bg-neutral-600 overflow-y-auto',
        'bottom-0', // prevents moving when TON wallet connect modal appears
        className,
      )}
      {...props}
    >
      <NextLink
        href="/"
        className="h-[52px] w-[52px] mb-2 flex flex-shrink-0 justify-center items-center"
      >
        <LogoSvg className="w-[34px]" />
      </NextLink>

      <nav className="flex flex-col w-full px-1 grow gap-2">
        <SidebarNavLink
          {...sidebarNavItems.store}
          isActive={({ pathname }) =>
            ['/', '/apps', '/games', '/upcoming', '/hot-offers', '/search'].includes(pathname)
          }
        />

        <SidebarNavLink {...sidebarNavItems.spaces} onClick={handleProtectedNavItemClick} />

        <SidebarNavLink {...sidebarNavItems.boost} onClick={handleProtectedNavItemClick} />

        {featureToggles.launchpad && (
          <SidebarNavLink
            {...sidebarNavItems.launchpad}
            onClick={() => handleTrackedLinkClick(MIXPANEL_EVENT_NAMES.LAUNCHPAD_LINK_CLICK)}
          />
        )}

        {featureToggles.perks && <SidebarNavLink {...sidebarNavItems.perks} />}

        {featureToggles.staking && (
          <SidebarNavLink
            {...sidebarNavItems.staking}
            onClick={() => handleTrackedLinkClick(MIXPANEL_EVENT_NAMES.STAKING_LINK_CLICK)}
          />
        )}

        <SidebarNavLink
          {...sidebarNavItems.otc}
          onClick={() => handleTrackedLinkClick(MIXPANEL_EVENT_NAMES.OTC_LINK_CLICK)}
        />

        <div className="flex flex-col gap-2 mt-auto">
          {isAuth && (
            <>
              <SidebarNavButton
                {...sidebarNavItems.notifications}
                compact
                onClick={() => notificationModal.open()}
              />
              {notificationModal.isOpen && <NotificationModal {...notificationModal.props} />}

              <SidebarNavButton
                {...sidebarNavItems.karma}
                compact
                onClick={() => modalKarma.open()}
                className="test123"
              />
              {modalKarma.isOpen && <KarmaModal {...modalKarma.props} />}
            </>
          )}

          <SidebarNavLink {...sidebarNavItems.docs} compact />

          <SidebarNavAccountButton />
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
